'use client'

import type { ReactNode } from 'react'
import classes from './styles.module.scss'
import Footer from '../Layout/Footer'
import Header from './Header'

type CleanLayoutType = {
  children: ReactNode
  hideFooter?: boolean
}

export default function CleanLayout({ children, hideFooter }: CleanLayoutType) {
  return (
    <>
      <Header />
      <div className={classes.root}>{children}</div>
      {!hideFooter && <Footer />}
    </>
  )
}
