import { AppBar } from '@patrianna/core-components'
import LogoLink from 'src/containers/LogoLink'
import classes from './styles.module.scss'

export default function Header() {
  return (
    <AppBar className={classes.root} fixed>
      <div className={classes.logoContainer}>
        <LogoLink />
      </div>
    </AppBar>
  )
}
