'use client'

import { useEffect } from 'react'
import CleanLayout from 'containers/Layouts/CleanLayout'
import Button from 'src/components/Button'
import Page from 'src/components/Page'
import * as Sentry from '@sentry/nextjs'
import ROUTES from 'temp/routes.json'
import { useTranslation } from 'app/context/translations'
import classes from './error.module.scss'

interface ErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function Error(props: ErrorProps) {
  const { error, reset } = props
  const t = useTranslation()

  useEffect(() => {
    console.error(error)
    Sentry.captureException(error)
  }, [error])

  return (
    <CleanLayout>
      <Page className={classes.root}>
        <h4 className={classes.title}>{t('common.unexpected_error_occured')}</h4>

        <div>
          <Button
            variant='contained'
            className={classes.btn}
            onClick={() => {
              document.location.href = ROUTES.HOME
            }}
          >
            {t('common.go_to_home')}
          </Button>

          <Button
            variant='outlined'
            className={classes.btn}
            onClick={() => {
              reset()
            }}
          >
            {t('common.reload_page')}
          </Button>
        </div>
      </Page>
    </CleanLayout>
  )
}
