import type { ReactNode } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'

type PageProps = {
  children?: ReactNode
  className?: string
  rootClassName?: string
}

function Page(props: PageProps) {
  return (
    <section className={cx(classes.root, props.rootClassName && props.rootClassName)}>
      <div className={cx(classes.content, props.className, 'mt-paper')}>{props.children}</div>
    </section>
  )
}

export default Page
