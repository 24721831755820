'use client'
import type { ReactNode } from 'react'
import cx from 'classnames'
import type { ButtonBaseProps } from '@patrianna/core-components'
import { ButtonBase } from '@patrianna/core-components'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import classes from './styles.module.scss'

export type ButtonPropsType = Omit<ButtonBaseProps, 'color'>

type Props = {
  children?: ReactNode
  disabled?: boolean
  color?: Currencies
  className?: string
  loading?: boolean
} & ButtonPropsType

function Button(props: Props) {
  const { color, children, className, variant, loading, ...rest } = props

  return (
    <ButtonBase
      {...rest}
      onClick={props?.onClick}
      className={cx(classes.root, className, color && classes[color], { [classes.loading]: loading })}
      variant={variant || 'contained'}
    >
      {children}
    </ButtonBase>
  )
}

export default Button
